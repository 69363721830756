"use client";
import Link from "next/link";

export default function ErrorPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Something went wrong</h1>
      <p className="text-lg mb-8">Sorry, an unexpected error has occurred.</p>
      <Link className="text-blue-500 underline" href="/">
        Go back to the homepage
      </Link>
    </div>
  );
}
